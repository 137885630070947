"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_LOGIN = exports.UPDATE_CAMPAIGN_CANDIDATE_INFORMATION = exports.UPDATE_USER_WORKSFOR_COMPANIES_FILTERS = exports.UPDATE_CANDIDATE_CONFERENCE_SCHEDULE = exports.UPDATE_USER_SETTINGS = exports.USER_DELETE_SHOWCASE = exports.USER_UPDATE_SHOWCASE = exports.USER_CREATE_SHOWCASE = exports.UPDATE_SKILL_SUMMARY_MANUALLY_INPUT = exports.UPDATE_USER_USERNAME = exports.UPDATE_SHOWCASE_RESPONSE = exports.UPDATE_USER_OVERVIEW = exports.USER_VOTE = exports.DISABLE_COLLABORATOR_INVITE = exports.FLAG_QUESTION = exports.UPDATE_INTERVIEW_NOTES = exports.DECLINE_INVITATION = exports.ACCEPT_INVITATION = exports.UPDATE_CANDIDATE_LIVE_INTERVIEW_STATUS = exports.DID_UPDATE_CAMPAIGN = exports.GET_SUMMARY_OF_COMPANIES_BY_ID = exports.GET_SUMMARY_OF_CAMPAIGNS_BY_ID = exports.SEND_LIVE_INTERVIEW_INVITATION_EMAIL = exports.SEND_COLLABORATION_INVITE = exports.UPDATE_RESPONSE_REVIEW = exports.CREATE_RESPONSE_REVIEW = exports.INITIALIZE_PAYMENT_PORTAL = exports.UPDATE_CANDIDATE_INVITATION_STATUS = exports.CREATE_SUBSCRIPTION = exports.UPDATE_CAMPAIGN_QUESTIONS = exports.UPDATE_CAMPAIGN_SETTINGS = exports.INVITE_CANDIDATES = exports.REPROCESS_CANDIDATE = exports.REPROCESS_RESPONSE = exports.UPDATE_COMPANY_QUESTION = exports.CREATE_CAMPAIGN = exports.DUPLICATE_CAMPAIGN = exports.REGISTER_CANDIDATE = exports.FINISH_REGISTRATION = exports.CREATE_COMPANY = exports.CREATE_COMPANY_QUESTION = exports.UPDATE_COMPANY_SETTINGS = exports.USER_SET_WIZARD = exports.UPDATE_APPLICATION_STATUS = exports.RESEND_CAMPAIGN_INVITATION = exports.COMPLETE_INTERVIEW_STAGE = exports.HIRE_CANDIDATE = exports.RELEASE_CANDIDATE_FROM_CAMPAIGN = exports.UPDATE_CANDIDATE_PROGRESS_STATUS = exports.REPROCESS_CAMPAIGN = void 0;
exports.CREATE_RESPONSE_TEXT_ANSWER = exports.UPDATE_USER = exports.EXTRACT_JOB_DESCRIPTION = exports.GENERATE_PROFILE_INTERVIEW_QUESTIONS = exports.CREATE_JOB_PROFILE_QUESTIONS = exports.CREATE_JOB_PROFILE = exports.UPDATE_JOB_PROFILE_QUESTION = void 0;
var react_1 = require("../generated/react");
exports.REPROCESS_CAMPAIGN = (0, react_1.gql)("\n  mutation REPROCESS_CAMPAIGN ($campaignId: ID!) {\n    reprocessCampaign(campaignId: $campaignId)\n  }\n");
exports.UPDATE_CANDIDATE_PROGRESS_STATUS = (0, react_1.gql)("\n  mutation UPDATE_CANDIDATE_PROGRESS_STATUS(\n    $campaignId: ID\n    $candidateId: ID\n    $status: CandidateCampaignStatus\n    $started: DateTime\n    $completed: DateTime\n  ) {\n    createCandidateProgressStatuses(\n      input: {\n        name: $status\n        started: $started\n        completed: $completed\n        relatedCampaign: { connect: { where: { node: { id: $campaignId } } } }\n        relatedCandidate: { connect: { where: { node: { id: $candidateId } } } }\n      }\n    ) {\n      candidateProgressStatuses {\n        name\n        started\n        relatedCampaign {\n          id\n        }\n        relatedCandidate {\n          id\n        }\n      }\n    }\n  }\n");
exports.RELEASE_CANDIDATE_FROM_CAMPAIGN = (0, react_1.gql)("\n  mutation RELEASE_CANDIDATE_FROM_CAMPAIGN($campaignId: ID!, $candidateId: ID!) {\n    releaseCandidate(campaignId: $campaignId, candidateId: $candidateId)\n  }\n");
exports.HIRE_CANDIDATE = (0, react_1.gql)("\n  mutation HIRE_CANDIDATE($campaignId: ID!, $candidateId: ID!) {\n    hireCandidate(campaignId: $campaignId, candidateId: $candidateId)\n  }\n");
exports.COMPLETE_INTERVIEW_STAGE = (0, react_1.gql)("\n  mutation COMPLETE_INTERVIEW_STAGE($campaignId: ID!, $candidateId: ID!) {\n    completeInterviewStage(campaignId: $campaignId, candidateId: $candidateId)\n  }\n");
exports.RESEND_CAMPAIGN_INVITATION = (0, react_1.gql)("\n  mutation RESEND_CAMPAIGN_INVITATION ($campaignId: String!, $candidateId: String!) {\n    resendInvitation(campaignId: $campaignId, candidateId: $candidateId)\n  }\n");
exports.UPDATE_APPLICATION_STATUS = (0, react_1.gql)("\n  mutation UPDATE_APPLICATION_STATUS($candidateId: ID!, $rejectReason: String!, $invitationStatus: String!) {\n    updateCandidates(where: {id: $candidateId} update: {invitationStatus: $invitationStatus, status: $invitationStatus, rejectReason: $rejectReason}){\n      candidates {\n        id\n        fullName\n        rejectReason\n        invitationStatus\n        status\n      }\n    }\n  }\n");
exports.USER_SET_WIZARD = (0, react_1.gql)("\n  mutation USER_SET_WIZARD($userId: ID!) {\n    updateUsers(where: { id: $userId },update: { finishedOnboardingWizard: true }) {\n      users {\n        finishedOnboardingWizard\n      }\n    }\n  }\n");
exports.UPDATE_COMPANY_SETTINGS = (0, react_1.gql)("\n  mutation UPDATE_COMPANY_SETTINGS($id: ID, $update: CompanyUpdateInput) {\n    updateCompanies(where: { id: $id }, update: $update) {\n      companies {\n        id\n        industry\n        website\n        name\n        description\n        settings {\n          defaultCompletionRedirectUrl\n          hideIdentity\n          webhookurl\n          usBasedCompany\n          liveInterviewLinkDurationInDays\n        }\n      }\n    }\n  }\n");
exports.CREATE_COMPANY_QUESTION = (0, react_1.gql)("\n  mutation CREATE_COMPANY_QUESTION($input: CampaignQuestionCreateInput!) {\n    createCampaignQuestions(input: [$input]) {\n      campaignQuestions {\n        id\n        label\n        answer\n    }\n    }\n  }\n");
exports.CREATE_COMPANY = (0, react_1.gql)("\n  mutation createCompanies($input: CompanyCreateInput!) {\n    createCompanies(input: [$input]) {\ncompanies {\n        name\n        id\n}\n    }\n  }\n");
exports.FINISH_REGISTRATION = (0, react_1.gql)("\n  mutation FINISH_REGISTRATION($input: FinishRegistrationInput!) {\n    finishRegistration(input: $input) {\n        id\n        email\n        verified\n        campaignProgressStatus{\n          name\n          started\n          completed\n        }\n    }\n  }\n");
exports.REGISTER_CANDIDATE = (0, react_1.gql)("\n  mutation REGISTER_CANDIDATE($input: RegisterCandidateInput!) {\n    registerCandidate(input: $input) {\n      id\n      verified\n      invitationStatus\n    }\n  }\n");
exports.DUPLICATE_CAMPAIGN = (0, react_1.gql)("\n  mutation DUPLICATE_CAMPAIGN($input: DuplicateCampaignInput!) {\n    duplicateCampaign(input: $input) {\n      id\n      label\n    }\n  }\n");
exports.CREATE_CAMPAIGN = (0, react_1.gql)("\n  mutation createCampaigns($input: CampaignCreateInput!) {\n    createCampaigns(input: [$input]) {\ncampaigns {\n        label\n        id\n}\n      }\n  }\n");
exports.UPDATE_COMPANY_QUESTION = (0, react_1.gql)("\n  mutation UPDATE_COMPANY_QUESTION($update: CampaignQuestionUpdateInput, $id: ID) {\n    updateCampaignQuestions(where: { id: $id }, update: $update) {\n      campaignQuestions {\n        id\n        label\n        answer\n        archived\n      }\n    }\n  }\n");
exports.REPROCESS_RESPONSE = (0, react_1.gql)("\n  mutation REPROCESS_RESPONSE($responseId: ID!) {\n    reprocessResponse(responseId: $responseId)\n  }\n");
exports.REPROCESS_CANDIDATE = (0, react_1.gql)("\n  mutation REPROCESS_CANDIDATE($candidateId: ID!) {\n    reprocessCandidate(candidateId: $candidateId)\n  }\n");
exports.INVITE_CANDIDATES = (0, react_1.gql)("\n  mutation INVITE_CANDIDATES($emails: [String!]!, $campaignId: String!, $companyId: String!) {\n    inviteCandidates(emails: $emails, campaignId: $campaignId, companyId: $companyId )\n  }\n");
exports.UPDATE_CAMPAIGN_SETTINGS = (0, react_1.gql)("\n  mutation UPDATE_CAMPAIGN_SETTINGS($update: CampaignUpdateInput, $campaignId: ID!) {\n    updateCampaigns(where: { id: $campaignId }, update: $update) {\n      campaigns {\n        id\n        label\n        location\n        accessType\n        mode\n        type\n        emailInfo\n        jobPostingUrl\n        completionRedirectUrl\n        verificationLabel\n        verificationDescription\n        pausedDescription\n        closedDescription\n        enableCandidateReminders\n        minimumRequiredReviewers\n      }\n    }\n  }\n");
exports.UPDATE_CAMPAIGN_QUESTIONS = (0, react_1.gql)("\n  mutation UPDATE_CAMPAIGN_QUESTIONS(\n    $questionList: [CampaignQuestionsConnectFieldInput!]\n    $questionIds: [ID!]\n    $campaignId: ID!\n  ) {\n    updateCampaigns(\n      where: { id: $campaignId }\n      connect: { questions: $questionList }\n      disconnect: { questions: [{ where: { NOT: { node:{id_IN: $questionIds} } } }] }\n    ) {\n      campaigns {\n        questions {\n          label\n        }\n      }\n    }\n  }\n");
exports.CREATE_SUBSCRIPTION = (0, react_1.gql)("\n  mutation CREATE_SUBSCRIPTION(\n    $planId: String!\n    $email: String!\n    $companyId: String!\n    $companyName: String!\n    $redirectUrl: String!\n  ) {\n    createSubscription(\n      planId: $planId\n      email: $email\n      companyId: $companyId\n      companyName: $companyName\n      redirectUrl: $redirectUrl\n    ) {\n      id\n      type\n      url\n      state\n      embed\n      created_at\n      expires_at\n      object\n      updated_at\n      resource_version\n    }\n  }\n");
exports.UPDATE_CANDIDATE_INVITATION_STATUS = (0, react_1.gql)("\n  mutation UPDATE_CANDIDATE_INVITATION_STATUS($candidateId: ID!, $invitationStatus: String!) {\n    updateCandidates(where: { id: $candidateId }, update: { invitationStatus: $invitationStatus}) {\n      candidates {\n        email\n        invitationStatus\n        status\n      }\n    }\n  }\n");
exports.INITIALIZE_PAYMENT_PORTAL = (0, react_1.gql)("\n  mutation INITIALIZE_PAYMENT_PORTAL($customerId: String!) {\n    initializePaymentPortal (customerId: $customerId) {\n      id\n      access_url\n    }\n  }\n");
exports.CREATE_RESPONSE_REVIEW = (0, react_1.gql)("\n  mutation CREATE_RESPONSE_REVIEW(\n    $responseId: ID\n    $edge: EvaluatedPropertiesCreateInput!\n    $userId: ID!\n  ) {\n    updateResponses(\n      where: { id: $responseId }\n      update: { usersEvaluated: { connect: { where:{node:{id:$userId}},edge: $edge } } }\n    ) {\n      responses {\n        transcript\n        answeredCampaignQuestions {\n          label\n        }\n        answeredJobProfileQuestions {\n          label\n        }\n      }\n    }\n  }\n");
exports.UPDATE_RESPONSE_REVIEW = (0, react_1.gql)("\n  mutation UPDATE_RESPONSE_REVIEW(\n  $responseId: ID!\n  $edgeId: String\n  $edge: EvaluatedPropertiesUpdateInput!\n  $userId: ID!\n) {\n  updateResponses(\n    where: { id: $responseId }\n    update: {\n      usersEvaluated: {\n        where: {node:{id:$userId}, edge: { id: $edgeId } }\n        update: { edge: $edge }\n      }\n    }\n  ) {\n    responses {\n      id\n      candidatesSubmitted {\n        fullName\n        email\n      }\n      answeredCampaignQuestions {\n          label\n          answer\n      }\n      answeredJobProfileQuestions {\n        label\n      }\n      usersEvaluated {\n        evaluatedResponsesConnection(where: { edge: { id: $edgeId } }) {\n          edges {\n            score\n            comment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n");
exports.SEND_COLLABORATION_INVITE = (0, react_1.gql)("\n  mutation SEND_COLLABORATION_INVITE($companyId: String!, $email: String!) {\n    sendCollaborationInvite(companyId: $companyId, email: $email) {\n      email\n      inviteType\n      acceptCode\n    }\n  }\n");
exports.SEND_LIVE_INTERVIEW_INVITATION_EMAIL = (0, react_1.gql)("\n  mutation SEND_LIVE_INTERVIEW_INVITATION_EMAIL(\n    $companyId: String!\n    $campaignId: String!\n    $candidateId: String!\n    $calendarLink: String!\n  ) {\n    sendLiveInterviewInvitationEmail(\n      companyId: $companyId\n      campaignId: $campaignId\n      candidateId: $candidateId\n      calendarLink: $calendarLink\n    ) {\n      email\n      inviteType\n      acceptCode\n    }\n  }\n");
exports.GET_SUMMARY_OF_CAMPAIGNS_BY_ID = (0, react_1.gql)("\n  mutation GET_SUMMARY_OF_CAMPAIGNS_BY_ID($campaingIds: [ID!]){\n  getSummaryOfCampaignByIds(campaignIds: $campaingIds)\n}\n");
exports.GET_SUMMARY_OF_COMPANIES_BY_ID = (0, react_1.gql)("\n  mutation GET_SUMMARY_OF_COMPANIES_BY_ID($companyIds: [ID!]){\n  getSummaryOfCompany(companyIds: $companyIds)\n}\n");
exports.DID_UPDATE_CAMPAIGN = (0, react_1.gql)("\n  mutation DID_UPDATE_CAMPAIGN($campaignId: ID!){\n  didUpdateCampaign(campaignId: $campaignId)\n}\n");
exports.UPDATE_CANDIDATE_LIVE_INTERVIEW_STATUS = (0, react_1.gql)("\n  mutation UPDATE_CANDIDATE_LIVE_INTERVIEW_STATUS($acceptCode: ID!, $status: InviteStatus!, $campaignId: ID!) {\n  updateInvites(update:{status: $status} where: { acceptCode: $acceptCode, campaignSent: { id: $campaignId } }){\n    invites{\n    acceptCode\n    status\n    }\n  }\n}\n");
exports.ACCEPT_INVITATION = (0, react_1.gql)("\n  mutation ACCEPT_INVITATION(\n    $acceptCode: String!\n    $email: String!\n  ) {\n    answerCollaborationInvite(\n      acceptCode: $acceptCode\n      email: $email\n      accept: true\n    ) {\n      acceptCode\n      email\n    }\n  }\n");
exports.DECLINE_INVITATION = (0, react_1.gql)("\n  mutation DECLINE_INVITATION(\n    $acceptCode: String!\n    $email: String!\n  ) {\n    answerCollaborationInvite(\n      acceptCode: $acceptCode\n      email: $email\n      accept: false\n    ) {\n      acceptCode\n      email\n    }\n  }\n");
exports.UPDATE_INTERVIEW_NOTES = (0, react_1.gql)("\nmutation UPDATE_INTERVIEW_NOTES($note: String!, $candidateId: ID!) {\ncreateCandidateNotes(\ninput: {\nnote: $note\ncandidate: {\nconnect: {\nwhere: { node: { id: $candidateId } }\n}\n        }\n      }\n    ) {\n      candidateNotes {\n        note\n        id\n      }\n    }\n  }\n ");
exports.FLAG_QUESTION = (0, react_1.gql)("\nmutation FLAG_QUESTION(\n  $userId: String!, \n  $questionId: String!, \n  $message: String!, \n  $type: String!\n  )\n  {\n  flagQuestion(userId: $userId, questionId: $questionId, message: $message, type: $type)\n}\n");
exports.DISABLE_COLLABORATOR_INVITE = (0, react_1.gql)("\n  mutation DISABLE_COLLABORATOR_INVITE($email: String!) {\n    disableCollaboratorInvite(email: $email) {\n      email\n      status\n    }\n  }\n");
exports.USER_VOTE = (0, react_1.gql)("\n  mutation USER_VOTE($candidateId: ID!, $userId: ID!, $vote: HiringVote!) {\n  updateUsers(\n    where: { id: $userId }\n    connect: {\n      hiringVotesForCandidates: {\n        edge: { vote: $vote }\n        where: { node: { id: $candidateId } }\n      }\n    }\n  ) {\n    users {\n      email\n      id\n    }\n  }\n}\n");
exports.UPDATE_USER_OVERVIEW = (0, react_1.gql)("\n  mutation UPDATE_USER_OVERVIEW ($userId: ID!, $update:UserUpdateInput!) {\n    updateUsers (where:{id: $userId}, update: $update){\n      users {\n        id\n        fullName\n        bio\n      }\n    }\n  }\n");
exports.UPDATE_SHOWCASE_RESPONSE = (0, react_1.gql)("\n  mutation UPDATE_SHOWCASE_RESPONSE($userId: ID!, $update: UserUpdateInput!) {\n    updateUsers(where: { id: $userId }, update: $update) {\n      users {\n        appliedForCandidates {\n          submittedResponses {\n            id\n            showcased\n          }\n        }\n      }\n    }\n  }\n");
exports.UPDATE_USER_USERNAME = (0, react_1.gql)("\n  mutation UPDATE_USER_USERNAME($userId: ID!, $username: String!) {\n    updateUsername(userId: $userId, username: $username) {\n      username\n    }\n  }\n");
exports.UPDATE_SKILL_SUMMARY_MANUALLY_INPUT = (0, react_1.gql)("\n  mutation UPDATE_SKILL_SUMMARY_INPUT($userId: ID!, $update: UserUpdateInput!) {\n    updateUsers(where: { id: $userId }, update: $update) {\n      users {\n        skillSummary\n      }\n    }\n  }\n");
exports.USER_CREATE_SHOWCASE = (0, react_1.gql)("\nmutation USER_CREATE_SHOWCASE($input: ShowcaseCreateInput!) {\ncreateShowcases(input: [$input]) {\nshowcases {\n        title\n        description\n        isFeatured\n      }\n    }\n  }\n");
exports.USER_UPDATE_SHOWCASE = (0, react_1.gql)("\nmutation USER_UPDATE_SHOWCASE($showcaseId: ID!, $update: ShowcaseUpdateInput!) {\nupdateShowcases(where: { id: $showcaseId }, update: $update) {\nshowcases {\n        title\n        description\n        isFeatured\n      }\n    }\n  }\n");
exports.USER_DELETE_SHOWCASE = (0, react_1.gql)("\nmutation USER_DELETE_SHOWCASE($showcaseId: ID!) {\ndeleteShowcases(where: { id: $showcaseId }) {\nnodesDeleted\n}\n  }\n");
exports.UPDATE_USER_SETTINGS = (0, react_1.gql)("\n  mutation UPDATE_USER_SETTINGS($userId: ID!, $update: UserUpdateInput!) {\n    updateUsers(where: { id: $userId }, update: $update) {\n        users {\n          settings {\n          bioShow\n          responseShowcaseShow\n        }\n      }\n    }\n  }\n");
exports.UPDATE_CANDIDATE_CONFERENCE_SCHEDULE = (0, react_1.gql)("\n  mutation UPDATE_CANDIDATE_CONFERENCE_SCHEDULE($candidateId: ID!, $update: CandidateUpdateInput!) {\n    updateCandidates(where: { id: $candidateId }, update: $update) {\n      candidates {\n        conferenceInvitationLink\n        conferenceInvitationScheduledAt\n      }\n    }\n  }\n");
exports.UPDATE_USER_WORKSFOR_COMPANIES_FILTERS = (0, react_1.gql)("\n    mutation UPDATE_USER_WORKSFOR_COMPANIES_FILTERS (\n    $userId: ID!\n    $companyId: ID!\n    $update: WorksForPropertiesCreateInput!\n  ) {\n    updateUsers(\n      where: { id: $userId }\n      connect: {\n        worksForCompanies: { where: { node: { id: $companyId } }, edge: $update }\n      }\n    ) {\n      users {\n        worksForCompaniesConnection(where: { node: { id: $companyId } }) {\n          edges {\n            campaignFilterSearch\n            campaignFilterModes\n            campaignFilterLocation\n            campaignSort\n          }\n        }\n      }\n    }\n  }\n");
exports.UPDATE_CAMPAIGN_CANDIDATE_INFORMATION = (0, react_1.gql)("\n  mutation UPDATE_CAMPAIGN_CANDIDATE_INFORMATION (\n    $candidateId: ID!\n    $update: CandidateUpdateInput!\n  ) {\n      updateCandidates(\n          where: { id: $candidateId },\n          update: $update\n      ) {\n          candidates {\n              id\n              fullName\n              email\n          }\n      }\n  }\n");
exports.USER_LOGIN = (0, react_1.gql)("\n  mutation USER_LOGIN($email: String!, $redirect: String, $useCode: Boolean) {\n    login(email: $email, redirect: $redirect, useCode: $useCode) {\n      isNew\n    }\n  }\n");
exports.UPDATE_JOB_PROFILE_QUESTION = (0, react_1.gql)("\n  mutation UPDATE_JOB_PROFILE_QUESTION($id: ID!, $update: JobProfileQuestionUpdateInput!) {\n    updateJobProfileQuestions(\n        where: { id: $id }\n        update: $update\n    ) {\n        jobProfileQuestions {\n            label\n            id\n            tips\n            focus\n            type\n        }\n    }\n  }\n");
exports.CREATE_JOB_PROFILE = (0, react_1.gql)("\n  mutation CREATE_JOB_PROFILE($input: CreateJobProfileInput!) {\n    createJobProfile(input: $input) {\n      id\n    }\n  }\n");
exports.CREATE_JOB_PROFILE_QUESTIONS = (0, react_1.gql)("\n  mutation CREATE_JOB_PROFILE_QUESTIONS($input: [JobProfileQuestionCreateInput!]!) {\n    createJobProfileQuestions(input: $input) {\n      jobProfileQuestions {\n        id\n      }\n    }\n  }\n");
exports.GENERATE_PROFILE_INTERVIEW_QUESTIONS = (0, react_1.gql)("\n  mutation GENERATE_PROFILE_INTERVIEW_QUESTIONS($jobProfileId: ID!) {\n    generateJobProfileQuestions(jobProfileId: $jobProfileId) {\n      questions {\n        question\n        tips\n        type\n      }\n    }\n  }\n");
exports.EXTRACT_JOB_DESCRIPTION = (0, react_1.gql)("\n  mutation EXTRACT_JOB_DESCRIPTION($jobDescription: String!) {\n    extractJobDescriptionFields(jobDescription: $jobDescription) {\n        threadId\n       slug\n      jobDetails {\n      jobTitle\n      companyName\n      companyWebsite\n      recruiterEmail\n      location\n      employmentType\n      salaryRange\n      applicationDeadline\n      industry\n      requiredSkills\n      experienceLevel\n      educationRequirements\n      responsibilities\n      benefits\n      companyOverview\n       contactPerson\n      contactEmail\n       jobDescription\n     \n      }\n    }\n  }\n");
exports.UPDATE_USER = (0, react_1.gql)("\nmutation UPDATE_USER($id: ID!, $input: UserUpdateInput!) {\n  updateUsers(where: { id: $id }, update: $input) {\n    users{\n    id\n    username\n      fullName\n      phoneNumber\n    }\n  }\n}\n");
exports.CREATE_RESPONSE_TEXT_ANSWER = (0, react_1.gql)("\n  mutation CREATE_RESPONSE_TEXT_ANSWER($questionId: ID!, $transcript: String!) {\n   createResponses(\n    input: {\n      status: TRANSCRIPT_COMPLETE\n      voiceDetected: true\n      transcript: $transcript\n      answeredJobProfileQuestions: {\n        connect: {\n          where: { node: { id: $questionId } }\n        }\n      }\n    }\n  ) {\n    responses {\n      id\n    }\n  }\n  }\n");
